import { Col, Flex, Row, Skeleton, Typography } from "antd";
import { useEffect, useState } from "react";
import styles from "./index.module.less"
import { HomeGPTsModel } from "@/types/home";
import _ from "lodash";
import { useRouter } from "next/router";
import { getPathTitle, imgUrl } from "@/shared/utils";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import Link from "next/link";

const HomeRightTopGPTsItem = ({ dataList }: { dataList?: HomeGPTsModel[] }) => {
    const router = useRouter()
    const { t } = useTranslation('common');

    const onClick = (item?: HomeGPTsModel) => {
        if (item?.gpts_id != undefined) {
            // router.push(`/gpts/${item.gpts_id}`)
            router.push(`/gpts/${item?.gpts_id}/${getPathTitle(item?.title)}`)
        }
    }

    function createItem(index: number, item?: HomeGPTsModel) {
        return <Flex
            className={styles.flexItem}
            key={item.gpts_id + index}
            onClick={() => onClick(item)}
        >
            <span className={styles.indexItem}> {index + 1} </span>
            <Image
                className={styles.imgItem}
                src={imgUrl(item?.logo, "small")}
                loading="lazy"
                alt={"gpt"}
                width={200}
                height={200}
            // priority
            />
            <Link href={`/gpts/${item?.gpts_id}/${getPathTitle(item?.title)}`}>
                <span className={styles.titleItem}>
                    {item?.title}
                </span>
            </Link>
        </Flex>
    }

    function emptyItem(index: number) {
        return <Flex className={styles.flexItem}>
            <span className={styles.indexItem}> {index + 1} </span>
            <Skeleton.Avatar active className={styles.imgItem} />
            <Skeleton.Input active size={"small"} />
        </Flex>
    }

    return <div className={styles.mainContainer}>
        <h3>{t("topGPTs")}</h3>

        {_.isEmpty(dataList) ? <>
            {emptyItem(0)}
            {emptyItem(1)}
        </> : <>
            {(dataList ?? []).map((item, index) => {
                return createItem(index, item);
            })}
        </>}

    </div>
}


export default HomeRightTopGPTsItem;