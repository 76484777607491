import Head from 'next/head';
import type { GetServerSideProps, InferGetStaticPropsType } from 'next';
import { NextPageWithLayout } from "@/pages/_app";
import React from 'react';
import Layout from '../layouts';
import HomeContent from '@/components/Home';
import { AppSettings } from '@/shared/app-common';
import { getArticleListData, getArticleListHotData, getGptsListData, getGptsListHotData, getThemeData } from '@/lib/service';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const getServerSideProps: GetServerSideProps = async (context) => {

  const { locale, req, resolvedUrl, defaultLocale } = context;

  const themeRes = await getThemeData(locale)
  const gptsListRes = await getGptsListData(locale)
  const articleListRes = await getArticleListData({ "page_no": 1, "page_size": 10 }, locale)
  const gptsHotListRes = await getGptsListHotData(locale)
  const articleHotListRes = await getArticleListHotData(locale)

  const localeRes = await serverSideTranslations(locale, ['common'])

  const currentURL = `https://${req.headers.host}${resolvedUrl}`

  return {
    props: {
      // ...(await serverSideTranslations(locale, ['common'])),
      ...localeRes,
      "theme": { ...(themeRes?.data ?? {}) },
      "gptsList": [...(gptsListRes?.data ?? [])],
      "articleList": { ...(articleListRes ?? {}) },
      "gptsHotList": [...(gptsHotListRes?.data ?? [])],
      "articleHotList": [...(articleHotListRes?.data ?? [])],
      "herf": currentURL,
      locale
    },
  };
}

const Home: NextPageWithLayout<InferGetStaticPropsType<typeof getServerSideProps>> = (props) => {
  return <HomeContent data={props} />;
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  return (
    <Layout
      head={
        <Head>
          <title>{AppSettings.title}</title>
          <meta name="title" content={AppSettings.title} />
          <meta name="description" content={AppSettings.desc} />

          <meta property="og:title" content={AppSettings?.title} />
          <meta property="og:description" content={AppSettings?.desc} />
          <meta property="og:image" content={AppSettings.logoUrl} />
          <meta property="og:image:secure_url" content={AppSettings.logoUrl} />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={AppSettings?.title} />
          <meta name="twitter:description" content={AppSettings?.desc} />
          <meta name="twitter:image" content={AppSettings.logoUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href={page.props?.herf} />
          <link rel="icon" href={AppSettings.icoPath} />

          <meta name="google-adsense-account" content="ca-pub-6737499404477364" />
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6737499404477364"
            crossOrigin="anonymous" />
        </Head>
      }
    >
      {page}
    </Layout>
  );
};

export default Home;
