import React from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import { Skeleton } from 'antd';
import { imgix } from '@/shared/utils';
import Image from 'next/image';
import { AppSettings } from '@/shared/app-common';

const TopImage = ({ src }: { src?: string }) => {

  const imageSection = src ? (
    <div className={classNames(styles.heroSection, styles.heroImageSection)}>
      <Image
        className={styles.heroImage}
        src={imgix(src, { w: 1060, h: 362 })}
        width={1060}
        height={352}
        alt={AppSettings.name}
        title={AppSettings.name}
        // loading="lazy"
        priority
      />

      {/* <img
        className={styles.heroImage}
        src={imgix(src, { w: 1060, h: 362 })}
        alt="GPTs"
        title="GPTs"
        loading="lazy"
      /> */}
    </div>
  ) : (
    <div className={styles.skeletonDiv}>
      <Skeleton.Image active className={styles.activeImg} />
    </div>
  );

  return (
    <div className={styles.marketplaceHero}>
      {imageSection}
    </div>
  );
};

export default TopImage;

